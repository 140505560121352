import type { Query as Query_ie1thl } from '../pages/auth/callback/[provider]';
import type { Query as Query_10gbc7r } from '../pages/auth/callback/oidc';
import type { OptionalQuery as OptionalQuery_1jm47st } from '../pages/auth/email/[action]';
import type { Query as Query_htt5fu } from '../pages/auth/email/update_verify';
import type { OptionalQuery as OptionalQuery_899gpj } from '../pages/auth/login';
import type { Query as Query_3mrve3 } from '../pages/auth/oidc';
import type { OptionalQuery as OptionalQuery_omdq37 } from '../pages/forms/consulting';
import type { OptionalQuery as OptionalQuery_1jwlnal } from '../pages/forms/consulting/thanks';
import type { OptionalQuery as OptionalQuery_1yklv6w } from '../pages/forms/consulting-b';
import type { OptionalQuery as OptionalQuery_1yulgvv } from '../pages/forms/consulting-c';
import type { OptionalQuery as OptionalQuery_mjp18i } from '../pages/forms/seminar';
import type { OptionalQuery as OptionalQuery_1s5m766 } from '../pages/forms/seminar/thanks';
import type { OptionalQuery as OptionalQuery_18ncsel } from '../pages/media/author';

export const pagesPath = {
  "$404": {
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/404' as const, hash: url?.hash })
  },
  "agreements": {
    _subject: (subject: string | number) => ({
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/agreements/[subject]' as const, query: { subject }, hash: url?.hash })
    })
  },
  "auth": {
    "callback": {
      _provider: (provider: string | number) => ({
        $url: (url: { query: Query_ie1thl, hash?: string | undefined }) => ({ pathname: '/auth/callback/[provider]' as const, query: { provider, ...url.query }, hash: url.hash })
      }),
      "oidc": {
        $url: (url: { query: Query_10gbc7r, hash?: string | undefined }) => ({ pathname: '/auth/callback/oidc' as const, query: url.query, hash: url.hash })
      }
    },
    "email": {
      _action: (action: string | number) => ({
        $url: (url?: { query?: OptionalQuery_1jm47st | undefined, hash?: string | undefined } | undefined) => ({ pathname: '/auth/email/[action]' as const, query: { action, ...url?.query }, hash: url?.hash })
      }),
      "update_verify": {
        $url: (url: { query: Query_htt5fu, hash?: string | undefined }) => ({ pathname: '/auth/email/update_verify' as const, query: url.query, hash: url.hash })
      }
    },
    "login": {
      $url: (url?: { query?: OptionalQuery_899gpj | undefined, hash?: string | undefined } | undefined) => ({ pathname: '/auth/login' as const, query: url?.query, hash: url?.hash })
    },
    "logout": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/auth/logout' as const, hash: url?.hash })
    },
    "oidc": {
      $url: (url: { query: Query_3mrve3, hash?: string | undefined }) => ({ pathname: '/auth/oidc' as const, query: url.query, hash: url.hash })
    },
    "signup": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/auth/signup' as const, hash: url?.hash })
    }
  },
  "consulting": {
    "calendar": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/consulting/calendar' as const, hash: url?.hash })
    },
    "concierge": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/consulting/concierge' as const, hash: url?.hash })
    },
    "edu": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/consulting/edu' as const, hash: url?.hash })
    },
    "future": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/consulting/future' as const, hash: url?.hash })
    },
    "ideco": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/consulting/ideco' as const, hash: url?.hash })
    },
    "insurance": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/consulting/insurance' as const, hash: url?.hash })
    },
    "investment_courses": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/consulting/investment-courses' as const, hash: url?.hash })
    },
    "mutual_funds": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/consulting/mutual-funds' as const, hash: url?.hash })
    },
    "nisa": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/consulting/nisa' as const, hash: url?.hash })
    },
    "sbi": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/consulting/sbi' as const, hash: url?.hash })
    },
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/consulting' as const, hash: url?.hash })
  },
  "contact": {
    "biz": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/contact/biz' as const, hash: url?.hash }),
      "thanks": {
        $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/contact/biz/thanks' as const, hash: url?.hash })
      }
    },
    "customer": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/contact/customer' as const, hash: url?.hash })
    },
    "thanks": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/contact/thanks' as const, hash: url?.hash })
    },
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/contact' as const, hash: url?.hash })
  },
  "docs": {
    _slug: (slug?: string[] | undefined) => ({
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/docs/[[...slug]]' as const, query: { slug }, hash: url?.hash })
    }),
  },
  "faq": {
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/faq' as const, hash: url?.hash })
  },
  "feature": {
    "$1118aa": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/feature/1118aa' as const, hash: url?.hash })
    },
    "$207aa": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/feature/207aa' as const, hash: url?.hash })
    },
    "$207ab": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/feature/207ab' as const, hash: url?.hash })
    },
    "$207ac": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/feature/207ac' as const, hash: url?.hash })
    },
    "$207ad": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/feature/207ad' as const, hash: url?.hash })
    },
    "$208aa": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/feature/208aa' as const, hash: url?.hash })
    },
    "$422aa": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/feature/422aa' as const, hash: url?.hash })
    },
    "$425aa": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/feature/425aa' as const, hash: url?.hash })
    },
    "$425ab": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/feature/425ab' as const, hash: url?.hash })
    },
    "$425ac": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/feature/425ac' as const, hash: url?.hash })
    },
    "$606aa": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/feature/606aa' as const, hash: url?.hash })
    },
    "$628aa": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/feature/628aa' as const, hash: url?.hash })
    },
    "$628ba": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/feature/628ba' as const, hash: url?.hash })
    },
    "$628ca": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/feature/628ca' as const, hash: url?.hash })
    },
    "$628da": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/feature/628da' as const, hash: url?.hash })
    },
    "$628ea": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/feature/628ea' as const, hash: url?.hash })
    },
    "article": {
      _id: (id: string | number) => ({
        $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/feature/article/[id]' as const, query: { id }, hash: url?.hash })
      })
    },
    "consulting_edu": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/feature/consulting-edu' as const, hash: url?.hash })
    },
    "consulting_woman": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/feature/consulting-woman' as const, hash: url?.hash })
    },
    "fa": {
      _id: (id: string | number) => ({
        $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/feature/fa/[id]' as const, query: { id }, hash: url?.hash })
      })
    }
  },
  "forms": {
    "consulting": {
      "thanks": {
        $url: (url?: { query?: OptionalQuery_1jwlnal | undefined, hash?: string | undefined } | undefined) => ({ pathname: '/forms/consulting/thanks' as const, query: url?.query, hash: url?.hash })
      },
      $url: (url?: { query?: OptionalQuery_omdq37 | undefined, hash?: string | undefined } | undefined) => ({ pathname: '/forms/consulting' as const, query: url?.query, hash: url?.hash })
    },
    "consulting_b": {
      "reserve": {
        $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/forms/consulting-b/reserve' as const, hash: url?.hash })
      },
      $url: (url?: { query?: OptionalQuery_1yklv6w | undefined, hash?: string | undefined } | undefined) => ({ pathname: '/forms/consulting-b' as const, query: url?.query, hash: url?.hash })
    },
    "consulting_c": {
      "reserve": {
        $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/forms/consulting-c/reserve' as const, hash: url?.hash })
      },
      $url: (url?: { query?: OptionalQuery_1yulgvv | undefined, hash?: string | undefined } | undefined) => ({ pathname: '/forms/consulting-c' as const, query: url?.query, hash: url?.hash })
    },
    "seminar": {
      "thanks": {
        $url: (url?: { query?: OptionalQuery_1s5m766 | undefined, hash?: string | undefined } | undefined) => ({ pathname: '/forms/seminar/thanks' as const, query: url?.query, hash: url?.hash })
      },
      $url: (url?: { query?: OptionalQuery_mjp18i | undefined, hash?: string | undefined } | undefined) => ({ pathname: '/forms/seminar' as const, query: url?.query, hash: url?.hash })
    }
  },
  "invitations": {
    _uuid: (uuid: string | number) => ({
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/invitations/[uuid]' as const, query: { uuid }, hash: url?.hash })
    }),
    "new": {
      _customerSlug: (customerSlug: string | number) => ({
        $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/invitations/new/[customerSlug]' as const, query: { customerSlug }, hash: url?.hash })
      })
    }
  },
  "media": {
    "article": {
      _id: (id: string | number) => ({
        $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/media/article/[id]' as const, query: { id }, hash: url?.hash })
      }),
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/media/article' as const, hash: url?.hash })
    },
    "author": {
      _author_id: (author_id: string | number) => ({
        $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/media/author/[author_id]' as const, query: { author_id }, hash: url?.hash })
      }),
      "sitemap_xml": {
        $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/media/author/sitemap.xml' as const, hash: url?.hash })
      },
      $url: (url?: { query?: OptionalQuery_18ncsel | undefined, hash?: string | undefined } | undefined) => ({ pathname: '/media/author' as const, query: url?.query, hash: url?.hash })
    },
    "category": {
      _id: (id: string | number) => ({
        $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/media/category/[id]' as const, query: { id }, hash: url?.hash })
      }),
      "sitemap_xml": {
        $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/media/category/sitemap.xml' as const, hash: url?.hash })
      },
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/media/category' as const, hash: url?.hash })
    },
    "feature": {
      _id: (id: string | number) => ({
        $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/media/feature/[id]' as const, query: { id }, hash: url?.hash })
      }),
      "sitemap_xml": {
        $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/media/feature/sitemap.xml' as const, hash: url?.hash })
      },
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/media/feature' as const, hash: url?.hash })
    },
    "feeds": {
      "feed_xml": {
        $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/media/feeds/feed.xml' as const, hash: url?.hash })
      },
      "limo_json": {
        $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/media/feeds/limo.json' as const, hash: url?.hash })
      },
      "snf_xml": {
        $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/media/feeds/snf.xml' as const, hash: url?.hash })
      }
    },
    "search": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/media/search' as const, hash: url?.hash })
    },
    "sitemap_xml": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/media/sitemap.xml' as const, hash: url?.hash })
    },
    "tag": {
      _id: (id: string | number) => ({
        $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/media/tag/[id]' as const, query: { id }, hash: url?.hash })
      }),
      "sitemap_xml": {
        $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/media/tag/sitemap.xml' as const, hash: url?.hash })
      },
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/media/tag' as const, hash: url?.hash })
    },
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/media' as const, hash: url?.hash })
  },
  "money_seminar": {
    "$3_funds": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/money-seminar/3-funds' as const, hash: url?.hash })
    },
    "ideco": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/money-seminar/ideco' as const, hash: url?.hash })
    },
    "ideco2": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/money-seminar/ideco2' as const, hash: url?.hash })
    },
    "mortgage": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/money-seminar/mortgage' as const, hash: url?.hash })
    },
    "nisa_100": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/money-seminar/nisa-100' as const, hash: url?.hash })
    },
    "nisa_basic": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/money-seminar/nisa-basic' as const, hash: url?.hash })
    },
    "nisa_basic2": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/money-seminar/nisa-basic2' as const, hash: url?.hash })
    },
    "nisa_basic3": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/money-seminar/nisa-basic3' as const, hash: url?.hash })
    },
    "nisa_growth": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/money-seminar/nisa-growth' as const, hash: url?.hash })
    },
    "nisa_levelup": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/money-seminar/nisa-levelup' as const, hash: url?.hash })
    },
    "nisa_revise": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/money-seminar/nisa-revise' as const, hash: url?.hash })
    },
    "smart": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/money-seminar/smart' as const, hash: url?.hash })
    },
    "solution_50s": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/money-seminar/solution-50s' as const, hash: url?.hash })
    },
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/money-seminar' as const, hash: url?.hash })
  },
  "mypage": {
    "customer": {
      "confirmed": {
        $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/mypage/customer/confirmed' as const, hash: url?.hash })
      },
      "edit": {
        $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/mypage/customer/edit' as const, hash: url?.hash })
      },
      "email": {
        "edit": {
          $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/mypage/customer/email/edit' as const, hash: url?.hash })
        },
        "update": {
          $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/mypage/customer/email/update' as const, hash: url?.hash })
        },
        $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/mypage/customer/email' as const, hash: url?.hash })
      },
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/mypage/customer' as const, hash: url?.hash })
    },
    "help": {
      "how_to_meeting": {
        $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/mypage/help/how-to-meeting' as const, hash: url?.hash })
      }
    },
    "invitations": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/mypage/invitations' as const, hash: url?.hash })
    },
    "meet": {
      _customerUUID: (customerUUID: string | number) => ({
        $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/mypage/meet/[customerUUID]' as const, query: { customerUUID }, hash: url?.hash })
      }),
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/mypage/meet' as const, hash: url?.hash })
    },
    "next_reservation": {
      "thanks": {
        $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/mypage/next-reservation/thanks' as const, hash: url?.hash })
      },
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/mypage/next-reservation' as const, hash: url?.hash })
    },
    "shared_files": {
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/mypage/shared-files' as const, hash: url?.hash })
    },
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/mypage' as const, hash: url?.hash })
  },
  "pre_contracts": {
    _id: (id: string | number) => ({
      _customer_uuid: (customer_uuid: string | number) => ({
        $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/pre-contracts/[id]/[customer_uuid]' as const, query: { id, customer_uuid }, hash: url?.hash })
      })
    })
  },
  "reviews": {
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/reviews' as const, hash: url?.hash })
  },
  "shindan": {
    "result": {
      _investorType: (investorType: string | number) => ({
        $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/shindan/result/[investorType]' as const, query: { investorType }, hash: url?.hash })
      })
    }
  },
  "simulation": {
    "$3min": {
      "result": {
        _id: (id: string | number) => ({
          $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/simulation/3min/result/[id]' as const, query: { id }, hash: url?.hash })
        })
      },
      "start": {
        $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/simulation/3min/start' as const, hash: url?.hash })
      },
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/simulation/3min' as const, hash: url?.hash })
    },
    "nisa": {
      "result": {
        _id: (id: string | number) => ({
          $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/simulation/nisa/result/[id]' as const, query: { id }, hash: url?.hash })
        })
      },
      "start": {
        $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/simulation/nisa/start' as const, hash: url?.hash })
      },
      $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/simulation/nisa' as const, hash: url?.hash })
    }
  },
  "sitemap_xml": {
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/sitemap.xml' as const, hash: url?.hash })
  },
  "sitemap_index_xml": {
    $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/sitemap_index.xml' as const, hash: url?.hash })
  },
  "webinar": {
    _webinarSlug: (webinarSlug: string | number) => ({
      _customerSlug: (customerSlug: string | number) => ({
        $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/webinar/[webinarSlug]/[customerSlug]' as const, query: { webinarSlug, customerSlug }, hash: url?.hash })
      })
    })
  },
  $url: (url?: { hash?: string | undefined } | undefined) => ({ pathname: '/' as const, hash: url?.hash })
};

export type PagesPath = typeof pagesPath;

export const staticPath = {
  _well_known: {
    security_txt: '/.well-known/security.txt'
  },
  apple_touch_icon_png: '/apple-touch-icon.png',
  assets: {
    ads: {
      criteo: {
        co1_png: '/assets/ads/criteo/co1.png',
        co2_png: '/assets/ads/criteo/co2.png',
        co3_png: '/assets/ads/criteo/co3.png',
        coupon_png: '/assets/ads/criteo/coupon.png',
        coupon_logo_png: '/assets/ads/criteo/coupon_logo.png',
        extra_badge_0_png: '/assets/ads/criteo/extra_badge_0.png',
        extra_badge_1_png: '/assets/ads/criteo/extra_badge_1.png',
        extra_badge_2_png: '/assets/ads/criteo/extra_badge_2.png',
        sm1_png: '/assets/ads/criteo/sm1.png',
        sm2_png: '/assets/ads/criteo/sm2.png',
        sm3_png: '/assets/ads/criteo/sm3.png',
        sm4_png: '/assets/ads/criteo/sm4.png'
      }
    },
    advisers: {
      izumida_jpg: '/assets/advisers/izumida.jpg'
    },
    affiliate_modal: {
      moneiroconcierge_svg: '/assets/affiliate_modal/moneiroconcierge.svg',
      seminar_svg: '/assets/affiliate_modal/seminar.svg',
      shindan_svg: '/assets/affiliate_modal/shindan.svg'
    },
    after_seminar: {
      appeal_tab_1_svg: '/assets/after-seminar/appeal-tab-1.svg',
      appeal_tab_2_svg: '/assets/after-seminar/appeal-tab-2.svg',
      appeal_tab_3_svg: '/assets/after-seminar/appeal-tab-3.svg',
      button_bg_phone_svg: '/assets/after-seminar/button-bg-phone.svg',
      message_icon_png: '/assets/after-seminar/message-icon.png',
      mortgage: {
        arrow_down_png: '/assets/after-seminar/mortgage/arrow-down.png',
        balloon_in_float_banner_svg: '/assets/after-seminar/mortgage/balloon-in-float-banner.svg',
        man_in_rest_svg: '/assets/after-seminar/mortgage/man-in-rest.svg',
        man_moyamoya_svg: '/assets/after-seminar/mortgage/man-moyamoya.svg',
        mogereco_logo_subtitle_png: '/assets/after-seminar/mortgage/mogereco_logo_subtitle.png',
        subtitle_underline_png: '/assets/after-seminar/mortgage/subtitle_underline.png',
        woman_have_trouble_base_png: '/assets/after-seminar/mortgage/woman-have-trouble-base.png',
        woman_have_trouble_md_png: '/assets/after-seminar/mortgage/woman-have-trouble-md.png'
      }
    },
    archivements: {
      cover1_jpg: '/assets/archivements/cover1.jpg',
      cover2_jpg: '/assets/archivements/cover2.jpg',
      cover3_jpg: '/assets/archivements/cover3.jpg',
      cover4_jpg: '/assets/archivements/cover4.jpg',
      cover5_jpg: '/assets/archivements/cover5.jpg',
      logo_jpg: '/assets/archivements/logo.jpg',
      manekai_by_ameba_jpg: '/assets/archivements/manekai-by-ameba.jpg'
    },
    auth: {
      login_png: '/assets/auth/login.png',
      mail_action_png: '/assets/auth/mail-action.png',
      mail_png: '/assets/auth/mail.png'
    },
    bimi: {
      moneiro_pem: '/assets/bimi/moneiro.pem',
      moneiro_svg: '/assets/bimi/moneiro.svg'
    },
    books: {
      cover1_png: '/assets/books/cover1.png',
      cover2_jpg: '/assets/books/cover2.jpg',
      cover3_jpg: '/assets/books/cover3.jpg',
      cover4_jpg: '/assets/books/cover4.jpg',
      cover5_jpg: '/assets/books/cover5.jpg'
    },
    case: {
      arrow_down_black_png: '/assets/case/arrow-down-black.png',
      case_01_after_graph_png: '/assets/case/case-01-after-graph.png',
      case_01_before_graph_png: '/assets/case/case-01-before-graph.png',
      case_01_png: '/assets/case/case-01.png',
      case_02_after_graph_png: '/assets/case/case-02-after-graph.png',
      case_02_before_graph_png: '/assets/case/case-02-before-graph.png',
      case_02_png: '/assets/case/case-02.png',
      case_03_after_graph_png: '/assets/case/case-03-after-graph.png',
      case_03_before_graph_png: '/assets/case/case-03-before-graph.png',
      case_03_png: '/assets/case/case-03.png'
    },
    common: {
      paginate: {
        first_page_icon_svg: '/assets/common/paginate/first-page-icon.svg',
        last_page_icon_svg: '/assets/common/paginate/last-page-icon.svg',
        next_page_icon_svg: '/assets/common/paginate/next-page-icon.svg',
        prev_page_icon_svg: '/assets/common/paginate/prev-page-icon.svg'
      }
    },
    concier: {
      widgetHeader_png: '/assets/concier/widgetHeader.png'
    },
    consulting: {
      calendar: {
        prefer_female_disabled_pc_png: '/assets/consulting/calendar/prefer-female-disabled-pc.png',
        prefer_female_disabled_png: '/assets/consulting/calendar/prefer-female-disabled.png',
        prefer_female_pc_png: '/assets/consulting/calendar/prefer-female-pc.png',
        prefer_female_png: '/assets/consulting/calendar/prefer-female.png',
        prefer_male_disabled_pc_png: '/assets/consulting/calendar/prefer-male-disabled-pc.png',
        prefer_male_disabled_png: '/assets/consulting/calendar/prefer-male-disabled.png',
        prefer_male_pc_png: '/assets/consulting/calendar/prefer-male-pc.png',
        prefer_male_png: '/assets/consulting/calendar/prefer-male.png'
      },
      cases: {
        edu_circlegraph_after_base_png: '/assets/consulting/cases/edu-circlegraph-after-base.png',
        edu_circlegraph_after_md_png: '/assets/consulting/cases/edu-circlegraph-after-md.png',
        edu_circlegraph_before_base_png: '/assets/consulting/cases/edu-circlegraph-before-base.png',
        edu_circlegraph_before_md_png: '/assets/consulting/cases/edu-circlegraph-before-md.png',
        future_circlegraph_after_png: '/assets/consulting/cases/future-circlegraph-after.png',
        future_circlegraph_before_png: '/assets/consulting/cases/future-circlegraph-before.png',
        hoken_bar_graph_after_png: '/assets/consulting/cases/hoken-bar-graph-after.png',
        hoken_bar_graph_before_png: '/assets/consulting/cases/hoken-bar-graph-before.png',
        ideco_circlegraph_after_base_png: '/assets/consulting/cases/ideco-circlegraph-after-base.png',
        ideco_circlegraph_after_md_png: '/assets/consulting/cases/ideco-circlegraph-after-md.png',
        ideco_circlegraph_before_png: '/assets/consulting/cases/ideco-circlegraph-before.png',
        insurance_bargraph_after_png: '/assets/consulting/cases/insurance-bargraph-after.png',
        insurance_bargraph_before_png: '/assets/consulting/cases/insurance-bargraph-before.png',
        mutual_funds_circlegraph_after_base_png: '/assets/consulting/cases/mutual-funds-circlegraph-after-base.png',
        mutual_funds_circlegraph_after_md_png: '/assets/consulting/cases/mutual-funds-circlegraph-after-md.png',
        mutual_funds_circlegraph_before_base_png: '/assets/consulting/cases/mutual-funds-circlegraph-before-base.png',
        mutual_funds_circlegraph_before_md_png: '/assets/consulting/cases/mutual-funds-circlegraph-before-md.png',
        nisa_circlegraph_after_png: '/assets/consulting/cases/nisa-circlegraph-after.png',
        nisa_circlegraph_before_png: '/assets/consulting/cases/nisa-circlegraph-before.png'
      },
      concierge: {
        kv_illust_svg: '/assets/consulting/concierge/kv_illust.svg',
        oota_wav: '/assets/consulting/concierge/oota.wav',
        shinagawa_wav: '/assets/consulting/concierge/shinagawa.wav'
      },
      investment_courses: {
        arrow_down_png: '/assets/consulting/investment_courses/arrow-down.png',
        arrow_right_png: '/assets/consulting/investment_courses/arrow-right.png'
      },
      sbi: {
        cards_svg: '/assets/consulting/sbi/cards.svg'
      },
      top: {
        hero_base_png: '/assets/consulting/top/hero-base.png',
        hero_md_png: '/assets/consulting/top/hero-md.png',
        ogp_png: '/assets/consulting/top/ogp.png'
      }
    },
    dash_marker_png: '/assets/dash-marker.png',
    decoration: {
      filled_star_svg: '/assets/decoration/filled-star.svg',
      non_filled_star_svg: '/assets/decoration/non-filled-star.svg'
    },
    docs: {
      discontinue_yahoo_login: {
        step02_png: '/assets/docs/discontinue-yahoo-login/step02.png',
        step03_png: '/assets/docs/discontinue-yahoo-login/step03.png',
        step04_png: '/assets/docs/discontinue-yahoo-login/step04.png'
      },
      how_to_meeting: {
        ios_step_1_png: '/assets/docs/how-to-meeting/ios-step-1.png',
        ios_step_2_png: '/assets/docs/how-to-meeting/ios-step-2.png',
        mail_png: '/assets/docs/how-to-meeting/mail.png',
        pc_step_1_png: '/assets/docs/how-to-meeting/pc-step-1.png',
        pc_step_2_png: '/assets/docs/how-to-meeting/pc-step-2.png',
        pc_step_3_png: '/assets/docs/how-to-meeting/pc-step-3.png',
        pc_step_4_png: '/assets/docs/how-to-meeting/pc-step-4.png',
        pc_step_5_png: '/assets/docs/how-to-meeting/pc-step-5.png',
        pc_step_6_png: '/assets/docs/how-to-meeting/pc-step-6.png'
      }
    },
    dot_marker_png: '/assets/dot-marker.png',
    feature: {
      $1118aa: {
        consulting_case_pc_1_png: '/assets/feature/1118aa/consulting-case-pc-1.png',
        consulting_case_pc_2_png: '/assets/feature/1118aa/consulting-case-pc-2.png',
        consulting_case_pc_3_png: '/assets/feature/1118aa/consulting-case-pc-3.png',
        consulting_case_sp_1_png: '/assets/feature/1118aa/consulting-case-sp-1.png',
        consulting_case_sp_2_png: '/assets/feature/1118aa/consulting-case-sp-2.png',
        consulting_case_sp_3_png: '/assets/feature/1118aa/consulting-case-sp-3.png',
        ogp_png: '/assets/feature/1118aa/ogp.png',
        woman_sagasu_png: '/assets/feature/1118aa/woman-sagasu.png'
      },
      $207aa: {
        concier_upper_body_with_white_png: '/assets/feature/207aa/concier-upper-body-with-white.png',
        hero_base_jpg: '/assets/feature/207aa/hero-base.jpg',
        hero_md_jpg: '/assets/feature/207aa/hero-md.jpg',
        ogp_png: '/assets/feature/207aa/ogp.png',
        participants_graph_png: '/assets/feature/207aa/participants-graph.png',
        reason_1_png: '/assets/feature/207aa/reason-1.png',
        reason_2_png: '/assets/feature/207aa/reason-2.png',
        reason_3_png: '/assets/feature/207aa/reason-3.png',
        wc_sagasu_svg: '/assets/feature/207aa/wc-sagasu.svg',
        woman_hikaku_svg: '/assets/feature/207aa/woman-hikaku.svg',
        woman_shindan_start_with_white_png: '/assets/feature/207aa/woman-shindan-start-with-white.png',
        woman_wakaranai_svg: '/assets/feature/207aa/woman-wakaranai.svg'
      },
      $207ab: {
        hero_base_jpg: '/assets/feature/207ab/hero-base.jpg'
      },
      $207ac: {
        hero_base_jpg: '/assets/feature/207ac/hero-base.jpg',
        hero_md_jpg: '/assets/feature/207ac/hero-md.jpg',
        ogp_jpg: '/assets/feature/207ac/ogp.jpg',
        reason_3_png: '/assets/feature/207ac/reason-3.png'
      },
      $207ad_ideco: {
        bank_account_svg: '/assets/feature/207ad-ideco/bank-account.svg',
        hand_with_ideco_png: '/assets/feature/207ad-ideco/hand-with-ideco.png',
        hero_base_jpg: '/assets/feature/207ad-ideco/hero-base.jpg',
        hero_md_jpg: '/assets/feature/207ad-ideco/hero-md.jpg',
        idecotte_png: '/assets/feature/207ad-ideco/idecotte.png',
        illust_denkyu_svg: '/assets/feature/207ad-ideco/illust-denkyu.svg',
        money_over_hand_svg: '/assets/feature/207ad-ideco/money-over-hand.svg',
        money_svg: '/assets/feature/207ad-ideco/money.svg',
        ogp_jpg: '/assets/feature/207ad-ideco/ogp.jpg',
        pc_with_dollar_svg: '/assets/feature/207ad-ideco/pc-with-dollar.svg',
        point1_png: '/assets/feature/207ad-ideco/point1.png',
        point3_png: '/assets/feature/207ad-ideco/point3.png'
      },
      $208aa: {
        concier_upper_body_with_white_png: '/assets/feature/208aa/concier-upper-body-with-white.png',
        hero_base_png: '/assets/feature/208aa/hero-base.png',
        hero_md_png: '/assets/feature/208aa/hero-md.png',
        ogp_png: '/assets/feature/208aa/ogp.png',
        participants_graph_png: '/assets/feature/208aa/participants-graph.png',
        reason_1_png: '/assets/feature/208aa/reason-1.png',
        reason_2_png: '/assets/feature/208aa/reason-2.png',
        reason_3_png: '/assets/feature/208aa/reason-3.png',
        wc_sagasu_svg: '/assets/feature/208aa/wc-sagasu.svg',
        woman_hikaku_svg: '/assets/feature/208aa/woman-hikaku.svg',
        woman_shindan_start_with_white_png: '/assets/feature/208aa/woman-shindan-start-with-white.png',
        woman_wakaranai_svg: '/assets/feature/208aa/woman-wakaranai.svg'
      },
      $422aa: {
        guide_image_png: '/assets/feature/422aa/guide-image.png',
        hero_base_png: '/assets/feature/422aa/hero-base.png',
        hero_md_png: '/assets/feature/422aa/hero-md.png',
        know01_png: '/assets/feature/422aa/know01.png',
        know02_png: '/assets/feature/422aa/know02.png',
        know03_png: '/assets/feature/422aa/know03.png',
        ogp_png: '/assets/feature/422aa/ogp.png'
      },
      $425aa_bonds: {
        about_moneiro_1_png: '/assets/feature/425aa-bonds/about-moneiro-1.png',
        about_moneiro_2_png: '/assets/feature/425aa-bonds/about-moneiro-2.png',
        about_moneiro_3_png: '/assets/feature/425aa-bonds/about-moneiro-3.png',
        about_moneiro_4_png: '/assets/feature/425aa-bonds/about-moneiro-4.png',
        advice_png: '/assets/feature/425aa-bonds/advice.png',
        bean_light_bulb_png: '/assets/feature/425aa-bonds/bean-light-bulb.png',
        bill_png: '/assets/feature/425aa-bonds/bill.png',
        bundle_of_bills_png: '/assets/feature/425aa-bonds/bundle-of-bills.png',
        hero_base_png: '/assets/feature/425aa-bonds/hero-base.png',
        hero_md_png: '/assets/feature/425aa-bonds/hero-md.png',
        magnifying_glass_png: '/assets/feature/425aa-bonds/magnifying-glass.png',
        mv_female_png: '/assets/feature/425aa-bonds/mv-female.png',
        ogp_png: '/assets/feature/425aa-bonds/ogp.png',
        review_user_1_png: '/assets/feature/425aa-bonds/review-user-1.png',
        review_user_2_png: '/assets/feature/425aa-bonds/review-user-2.png',
        review_user_3_png: '/assets/feature/425aa-bonds/review-user-3.png',
        step1_png: '/assets/feature/425aa-bonds/step1.png',
        step2_png: '/assets/feature/425aa-bonds/step2.png',
        step3_png: '/assets/feature/425aa-bonds/step3.png',
        worries_1_png: '/assets/feature/425aa-bonds/worries-1.png',
        worries_2_png: '/assets/feature/425aa-bonds/worries-2.png',
        worries_3_png: '/assets/feature/425aa-bonds/worries-3.png'
      },
      $425ab_bonds: {
        advice_png: '/assets/feature/425ab-bonds/advice.png',
        step_1_png: '/assets/feature/425ab-bonds/step-1.png',
        step_2_png: '/assets/feature/425ab-bonds/step-2.png',
        step_3_png: '/assets/feature/425ab-bonds/step-3.png',
        worries_1_png: '/assets/feature/425ab-bonds/worries-1.png',
        worries_2_png: '/assets/feature/425ab-bonds/worries-2.png',
        worries_3_png: '/assets/feature/425ab-bonds/worries-3.png'
      },
      $425ac_bonds: {
        hero_base_png: '/assets/feature/425ac-bonds/hero-base.png',
        hero_md_png: '/assets/feature/425ac-bonds/hero-md.png',
        ogp_png: '/assets/feature/425ac-bonds/ogp.png'
      },
      $606aa: {
        hero_base_png: '/assets/feature/606aa/hero-base.png',
        hero_md_png: '/assets/feature/606aa/hero-md.png',
        ogp_jpg: '/assets/feature/606aa/ogp.jpg'
      },
      $628aa_insurance: {
        hero_md_png: '/assets/feature/628aa-insurance/hero-md.png',
        ogp_png: '/assets/feature/628aa-insurance/ogp.png',
        points: {
          how_to_choose: {
            point_01_png: '/assets/feature/628aa-insurance/points/how-to-choose/point-01.png',
            point_02_png: '/assets/feature/628aa-insurance/points/how-to-choose/point-02.png',
            point_03_png: '/assets/feature/628aa-insurance/points/how-to-choose/point-03.png'
          },
          moneiro_strength: {
            point_01_png: '/assets/feature/628aa-insurance/points/moneiro-strength/point-01.png',
            point_02_png: '/assets/feature/628aa-insurance/points/moneiro-strength/point-02.png',
            point_03_png: '/assets/feature/628aa-insurance/points/moneiro-strength/point-03.png'
          }
        }
      },
      $628ba_insurance: {
        ogp_png: '/assets/feature/628ba-insurance/ogp.png',
        points: {
          how_to_choose: {
            point_02_png: '/assets/feature/628ba-insurance/points/how-to-choose/point-02.png'
          }
        }
      },
      $628ca_insurance: {
        ogp_png: '/assets/feature/628ca-insurance/ogp.png',
        points: {
          how_to_choose: {
            point_02_png: '/assets/feature/628ca-insurance/points/how-to-choose/point-02.png'
          }
        }
      },
      $628da_insurance: {
        ogp_png: '/assets/feature/628da-insurance/ogp.png',
        points: {
          how_to_choose: {
            point_02_png: '/assets/feature/628da-insurance/points/how-to-choose/point-02.png'
          }
        }
      },
      $628ea_insurance: {
        ogp_png: '/assets/feature/628ea-insurance/ogp.png',
        points: {
          how_to_choose: {
            point_02_png: '/assets/feature/628ea-insurance/points/how-to-choose/point-02.png'
          }
        }
      },
      fa: {
        asset_icon_png: '/assets/feature/fa/asset-icon.png',
        bank_icon_png: '/assets/feature/fa/bank-icon.png',
        beginner_icon_png: '/assets/feature/fa/beginner-icon.png',
        cap_icon_png: '/assets/feature/fa/cap-icon.png',
        clip_png: '/assets/feature/fa/clip.png',
        glass_icon_png: '/assets/feature/fa/glass-icon.png',
        line_sp_png: '/assets/feature/fa/line-sp.png',
        line_png: '/assets/feature/fa/line.png',
        nishimura: {
          hero_base_png: '/assets/feature/fa/nishimura/hero-base.png',
          hero_md_png: '/assets/feature/fa/nishimura/hero-md.png',
          nishimura_png: '/assets/feature/fa/nishimura/nishimura.png'
        },
        note_icon_png: '/assets/feature/fa/note-icon.png',
        og_png: '/assets/feature/fa/og.png',
        ogino: {
          hero_base_png: '/assets/feature/fa/ogino/hero-base.png',
          hero_md_png: '/assets/feature/fa/ogino/hero-md.png',
          ogino_png: '/assets/feature/fa/ogino/ogino.png'
        },
        sasamura: {
          hero_base_png: '/assets/feature/fa/sasamura/hero-base.png',
          hero_md_png: '/assets/feature/fa/sasamura/hero-md.png',
          sasamura_png: '/assets/feature/fa/sasamura/sasamura.png'
        },
        slash_left_png: '/assets/feature/fa/slash-left.png',
        slash_right_png: '/assets/feature/fa/slash-right.png',
        step01_png: '/assets/feature/fa/step01.png',
        step02_png: '/assets/feature/fa/step02.png',
        step03_png: '/assets/feature/fa/step03.png',
        sugahara: {
          hero_base_png: '/assets/feature/fa/sugahara/hero-base.png',
          hero_md_png: '/assets/feature/fa/sugahara/hero-md.png',
          sugahara_png: '/assets/feature/fa/sugahara/sugahara.png'
        },
        thumbnail_png: '/assets/feature/fa/thumbnail.png',
        ueda: {
          hero_base_png: '/assets/feature/fa/ueda/hero-base.png',
          hero_md_png: '/assets/feature/fa/ueda/hero-md.png',
          ueda_png: '/assets/feature/fa/ueda/ueda.png'
        }
      }
    },
    forms: {
      concier_doc2x_png: '/assets/forms/concier-doc2x.png'
    },
    illust: {
      $3min_result_illust_lifeplan_png: '/assets/illust/3min-result-illust-lifeplan.png',
      $3min_result_illust_look_over_png: '/assets/illust/3min-result-illust-look-over.png',
      $3min_result_illust_preparation_png: '/assets/illust/3min-result-illust-preparation.png',
      applying_svg: '/assets/illust/applying.svg',
      asuka_svg: '/assets/illust/asuka.svg',
      calendar_svg: '/assets/illust/calendar.svg',
      commentary_png: '/assets/illust/commentary.png',
      concier_3_png: '/assets/illust/concier-3.png',
      concier_upper_body_2_svg: '/assets/illust/concier-upper-body-2.svg',
      concier_upper_body_svg: '/assets/illust/concier-upper-body.svg',
      concierge_allok_svg: '/assets/illust/concierge-allok.svg',
      concierge_bowing_2_svg: '/assets/illust/concierge-bowing-2.svg',
      concierge_bowing_svg: '/assets/illust/concierge-bowing.svg',
      concierge_fight_svg: '/assets/illust/concierge-fight.svg',
      concierge_free_svg: '/assets/illust/concierge-free.svg',
      concierge_smile_svg: '/assets/illust/concierge-smile.svg',
      concierge_sweat_svg: '/assets/illust/concierge-sweat.svg',
      concierge_three_contents_2_svg: '/assets/illust/concierge-three-contents-2.svg',
      concierge_three_contents_svg: '/assets/illust/concierge-three-contents.svg',
      concierge_svg: '/assets/illust/concierge.svg',
      connecting_hand_svg: '/assets/illust/connecting-hand.svg',
      connecting_svg: '/assets/illust/connecting.svg',
      considering_assets_woman_png: '/assets/illust/considering-assets-woman.png',
      consultation_png: '/assets/illust/consultation.png',
      consulting_suggestion_svg: '/assets/illust/consulting-suggestion.svg',
      education_expenses_png: '/assets/illust/education-expenses.png',
      female_steady_png: '/assets/illust/female-steady.png',
      female_to_see_smartphone_happily_png: '/assets/illust/female-to-see-smartphone-happily.png',
      free_advice_online_text_png: '/assets/illust/free-advice-online-text.png',
      gg_check_o_png: '/assets/illust/gg_check-o.png',
      goal_after_png: '/assets/illust/goal-after.png',
      goal_before_png: '/assets/illust/goal-before.png',
      hand_with_smartphone_svg: '/assets/illust/hand-with-smartphone.svg',
      hokuhoku_png: '/assets/illust/hokuhoku.png',
      ideco_desc_woman_png: '/assets/illust/ideco-desc-woman.png',
      ideco_setsuzei_png: '/assets/illust/ideco-setsuzei.png',
      instructor_1_svg: '/assets/illust/instructor-1.svg',
      instructor_2_svg: '/assets/illust/instructor-2.svg',
      line_left_png: '/assets/illust/line_left.png',
      line_right_png: '/assets/illust/line_right.png',
      mail_with_lamp_svg: '/assets/illust/mail-with-lamp.svg',
      male_steady_svg: '/assets/illust/male-steady.svg',
      male_studying_svg: '/assets/illust/male-studying.svg',
      money_png: '/assets/illust/money.png',
      money_svg: '/assets/illust/money.svg',
      nisa_desc_woman_png: '/assets/illust/nisa-desc-woman.png',
      nisa_growth_man_png: '/assets/illust/nisa-growth-man.png',
      nisa_ideco_png: '/assets/illust/nisa-ideco.png',
      nisa_revise_desc_woman_png: '/assets/illust/nisa-revise-desc-woman.png',
      noservice_svg: '/assets/illust/noservice.svg',
      older_couple_svg: '/assets/illust/older-couple.svg',
      patient_svg: '/assets/illust/patient.svg',
      pc_chart_png: '/assets/illust/pc-chart.png',
      raise_zero_yen_svg: '/assets/illust/raise-zero-yen.svg',
      smartphone_wipe_woman_svg: '/assets/illust/smartphone-wipe-woman.svg',
      star_concierge_png: '/assets/illust/star-concierge.png',
      top_main_visual_svg: '/assets/illust/top-main-visual.svg',
      toshin_desc_3_woman_png: '/assets/illust/toshin-desc-3-woman.png',
      toshin_desc_3_png: '/assets/illust/toshin-desc-3.png',
      tsumi_nisa_desc_woman_png: '/assets/illust/tsumi-nisa-desc-woman.png',
      tsumitate_nisa_base_png: '/assets/illust/tsumitate-nisa-base.png',
      tsumitate_nisa_md_png: '/assets/illust/tsumitate-nisa-md.png',
      tsumitate_nisa_svg: '/assets/illust/tsumitate-nisa.svg',
      type_prudent_male_svg: '/assets/illust/type-prudent-male.svg',
      user_female_1_circle_svg: '/assets/illust/user-female-1-circle.svg',
      user_female_1_svg: '/assets/illust/user-female-1.svg',
      user_female_2_circle_svg: '/assets/illust/user-female-2-circle.svg',
      user_female_2_svg: '/assets/illust/user-female-2.svg',
      user_female_3_circle_svg: '/assets/illust/user-female-3-circle.svg',
      user_female_4_circle_svg: '/assets/illust/user-female-4-circle.svg',
      user_female_5_svg: '/assets/illust/user-female-5.svg',
      user_female_6_svg: '/assets/illust/user-female-6.svg',
      user_female_7_svg: '/assets/illust/user-female-7.svg',
      user_getlost_png: '/assets/illust/user-getlost.png',
      user_male_1_circle_svg: '/assets/illust/user-male-1-circle.svg',
      user_male_1_svg: '/assets/illust/user-male-1.svg',
      user_male_2_svg: '/assets/illust/user-male-2.svg',
      user_understand_svg: '/assets/illust/user-understand.svg',
      user_female_sigh_svg: '/assets/illust/user_female_sigh.svg',
      woman_3_of_each_age_png: '/assets/illust/woman-3-of-each-age.png',
      woman_3_png: '/assets/illust/woman-3.png',
      woman_inspired_png: '/assets/illust/woman-inspired.png',
      woman_introduction_for_couple_png: '/assets/illust/woman-introduction-for-couple.png',
      woman_toushi_image_svg: '/assets/illust/woman-toushi-image.svg',
      worried_man_upper_body_svg: '/assets/illust/worried-man-upper-body.svg'
    },
    illusts: {
      couple: {
        couple50s_svg: '/assets/illusts/couple/couple50s.svg',
        fufugenka_svg: '/assets/illusts/couple/fufugenka.svg',
        fufugenka2_svg: '/assets/illusts/couple/fufugenka2.svg',
        fufuhanashiai_svg: '/assets/illusts/couple/fufuhanashiai.svg',
        fufuhirameku_svg: '/assets/illusts/couple/fufuhirameku.svg',
        fufukangaeru_svg: '/assets/illusts/couple/fufukangaeru.svg',
        fufukomatta_svg: '/assets/illusts/couple/fufukomatta.svg',
        miboujin_svg: '/assets/illusts/couple/miboujin.svg',
        otto_kikoenai_svg: '/assets/illusts/couple/otto-kikoenai.svg',
        otto_kyohi_ukeuri_svg: '/assets/illusts/couple/otto-kyohi-ukeuri.svg',
        otto_kyohi_svg: '/assets/illusts/couple/otto-kyohi.svg',
        otto_mukanshin_svg: '/assets/illusts/couple/otto-mukanshin.svg',
        otto_shitsugyou_svg: '/assets/illusts/couple/otto-shitsugyou.svg',
        otto_shittakaburi_svg: '/assets/illusts/couple/otto-shittakaburi.svg',
        roufufu_yutorinashi_svg: '/assets/illusts/couple/roufufu-yutorinashi.svg',
        roufufu_svg: '/assets/illusts/couple/roufufu.svg',
        tsuma_iratsuki_svg: '/assets/illusts/couple/tsuma-iratsuki.svg'
      },
      family: {
        family_2people_svg: '/assets/illusts/family/family-2people.svg',
        family_3people_svg: '/assets/illusts/family/family-3people.svg',
        family_4people_svg: '/assets/illusts/family/family-4people.svg',
        family_dauhter_benkyo_svg: '/assets/illusts/family/family-dauhter-benkyo.svg',
        family_dauhter_svg: '/assets/illusts/family/family-dauhter.svg',
        family_ninkatu_svg: '/assets/illusts/family/family-ninkatu.svg',
        family_ryokou_svg: '/assets/illusts/family/family-ryokou.svg',
        family_shufu_svg: '/assets/illusts/family/family-shufu.svg',
        family_son_benkyo_svg: '/assets/illusts/family/family-son-benkyo.svg',
        family_son_hitorigurashi_svg: '/assets/illusts/family/family-son-hitorigurashi.svg',
        family_son_shiken_svg: '/assets/illusts/family/family-son-shiken.svg',
        family_son_soccer_svg: '/assets/illusts/family/family-son-soccer.svg',
        family_son_toukou_svg: '/assets/illusts/family/family-son-toukou.svg',
        family_son_svg: '/assets/illusts/family/family-son.svg'
      },
      insta: {
        ic_creditcard_svg: '/assets/illusts/insta/ic-creditcard.svg',
        ic_gamaguchi_svg: '/assets/illusts/insta/ic-gamaguchi.svg',
        ic_house_svg: '/assets/illusts/insta/ic-house.svg',
        ic_okanefukuro_svg: '/assets/illusts/insta/ic-okanefukuro.svg',
        ic_ranking_1_svg: '/assets/illusts/insta/ic-ranking-1.svg',
        ic_ranking_2_svg: '/assets/illusts/insta/ic-ranking-2.svg',
        ic_ranking_3_svg: '/assets/illusts/insta/ic-ranking-3.svg',
        ic_shitsumon_svg: '/assets/illusts/insta/ic-shitsumon.svg',
        ic_sodan_svg: '/assets/illusts/insta/ic-sodan.svg',
        ic_tsumitate_svg: '/assets/illusts/insta/ic-tsumitate.svg'
      },
      man_concierge: {
        mc_main_svg: '/assets/illusts/man-concierge/mc-main.svg',
        mc_sub_svg: '/assets/illusts/man-concierge/mc-sub.svg'
      },
      mono: {
        atm_svg: '/assets/illusts/mono/atm.svg',
        camera_off_svg: '/assets/illusts/mono/camera-off.svg',
        coin_svg: '/assets/illusts/mono/coin.svg',
        gakushi_svg: '/assets/illusts/mono/gakushi.svg',
        graph_svg: '/assets/illusts/mono/graph.svg',
        icon_ideco_svg: '/assets/illusts/mono/icon-ideco.svg',
        icon_kyoiku_svg: '/assets/illusts/mono/icon-kyoiku.svg',
        icon_nisa_svg: '/assets/illusts/mono/icon-nisa.svg',
        icon_shoshinsha_svg: '/assets/illusts/mono/icon-shoshinsha.svg',
        kaiyaku_svg: '/assets/illusts/mono/kaiyaku.svg',
        kaiyakusho_svg: '/assets/illusts/mono/kaiyakusho.svg',
        letterset_svg: '/assets/illusts/mono/letterset.svg',
        mail_svg: '/assets/illusts/mono/mail.svg',
        mirufiyu_sara_svg: '/assets/illusts/mono/mirufiyu-sara.svg',
        mirufiyu_svg: '/assets/illusts/mono/mirufiyu.svg',
        money_svg: '/assets/illusts/mono/money.svg',
        mono_ideconisa_svg: '/assets/illusts/mono/mono-ideconisa.svg',
        omamori_svg: '/assets/illusts/mono/omamori.svg',
        paper_notification_svg: '/assets/illusts/mono/paper-notification.svg',
        pc_chart_line_svg: '/assets/illusts/mono/pc-chart-line.svg',
        pc_chart_pie_svg: '/assets/illusts/mono/pc-chart-pie.svg',
        pc_svg: '/assets/illusts/mono/pc.svg',
        pig_chokinbako_line_bold_svg: '/assets/illusts/mono/pig-chokinbako-line-bold.svg',
        pig_chokinbako_svg: '/assets/illusts/mono/pig-chokinbako.svg',
        satsutaba_svg: '/assets/illusts/mono/satsutaba.svg',
        sp_pc_svg: '/assets/illusts/mono/sp-pc.svg',
        sp_svg: '/assets/illusts/mono/sp.svg',
        tenbin_svg: '/assets/illusts/mono/tenbin.svg',
        tsuchou_svg: '/assets/illusts/mono/tsuchou.svg',
        zoom_swipe_svg: '/assets/illusts/mono/zoom_swipe.svg'
      },
      real_concierge: {
        asuka_svg: '/assets/illusts/real-concierge/asuka.svg',
        izumida_svg: '/assets/illusts/real-concierge/izumida.svg',
        kamura_svg: '/assets/illusts/real-concierge/kamura.svg',
        miwa_svg: '/assets/illusts/real-concierge/miwa.svg',
        miyazawa_svg: '/assets/illusts/real-concierge/miyazawa.svg',
        morishige_svg: '/assets/illusts/real-concierge/morishige.svg',
        ozaki_svg: '/assets/illusts/real-concierge/ozaki.svg',
        sato_svg: '/assets/illusts/real-concierge/sato.svg'
      },
      shindan: {
        man_ando_svg: '/assets/illusts/shindan/man-ando.svg',
        man_diy_kenjitsu_svg: '/assets/illusts/shindan/man-diy-kenjitsu.svg',
        man_diy_shoubushi_svg: '/assets/illusts/shindan/man-diy-shoubushi.svg',
        man_kenjitsu_svg: '/assets/illusts/shindan/man-kenjitsu.svg',
        man_kyougaku_svg: '/assets/illusts/shindan/man-kyougaku.svg',
        man_shindan_start_svg: '/assets/illusts/shindan/man-shindan-start.svg',
        man_shoubushi_svg: '/assets/illusts/shindan/man-shoubushi.svg',
        woman_ando_svg: '/assets/illusts/shindan/woman-ando.svg',
        woman_diy_kenjitsu_svg: '/assets/illusts/shindan/woman-diy-kenjitsu.svg',
        woman_diy_shoubushi_svg: '/assets/illusts/shindan/woman-diy-shoubushi.svg',
        woman_goal_mienai_svg: '/assets/illusts/shindan/woman-goal-mienai.svg',
        woman_goal_mieta_svg: '/assets/illusts/shindan/woman-goal-mieta.svg',
        woman_hirameki_svg: '/assets/illusts/shindan/woman-hirameki.svg',
        woman_kenjitsu_svg: '/assets/illusts/shindan/woman-kenjitsu.svg',
        woman_kyougaku_svg: '/assets/illusts/shindan/woman-kyougaku.svg',
        woman_shindan_start_svg: '/assets/illusts/shindan/woman-shindan-start.svg',
        woman_shoubushi_svg: '/assets/illusts/shindan/woman-shoubushi.svg'
      },
      shiryo: {
        byositsu_svg: '/assets/illusts/shiryo/byositsu.svg',
        furusatonozei_svg: '/assets/illusts/shiryo/furusatonozei.svg',
        man_byouki_svg: '/assets/illusts/shiryo/man-byouki.svg',
        man_ctr_svg: '/assets/illusts/shiryo/man-ctr.svg',
        man_fuyuso_svg: '/assets/illusts/shiryo/man-fuyuso.svg',
        man_jyusin_svg: '/assets/illusts/shiryo/man-jyusin.svg',
        man_rihabiri_svg: '/assets/illusts/shiryo/man-rihabiri.svg',
        man_rosai_svg: '/assets/illusts/shiryo/man-rosai.svg',
        man_taiin_svg: '/assets/illusts/shiryo/man-taiin.svg',
        man_yakusho_svg: '/assets/illusts/shiryo/man-yakusho.svg',
        nouka_svg: '/assets/illusts/shiryo/nouka.svg',
        noukousoku_svg: '/assets/illusts/shiryo/noukousoku.svg',
        nurse_svg: '/assets/illusts/shiryo/nurse.svg',
        police_svg: '/assets/illusts/shiryo/police.svg',
        teinen_hataraku_svg: '/assets/illusts/shiryo/teinen-hataraku.svg',
        woman_60_over_svg: '/assets/illusts/shiryo/woman-60-over.svg',
        woman_byouki_svg: '/assets/illusts/shiryo/woman-byouki.svg',
        woman_kega_svg: '/assets/illusts/shiryo/woman-kega.svg',
        woman_kenkou_svg: '/assets/illusts/shiryo/woman-kenkou.svg',
        woman_metabo_svg: '/assets/illusts/shiryo/woman-metabo.svg',
        woman_new_tsumitate_nisa_svg: '/assets/illusts/shiryo/woman-new-tsumitate-nisa.svg',
        woman_nyuin_svg: '/assets/illusts/shiryo/woman-nyuin.svg',
        woman_nyuinhi_svg: '/assets/illusts/shiryo/woman-nyuinhi.svg',
        woman_sagasu_nisa_svg: '/assets/illusts/shiryo/woman-sagasu-nisa.svg',
        woman_toushi_image_svg: '/assets/illusts/shiryo/woman-toushi-image.svg',
        woman_tsumitate_nisa_svg: '/assets/illusts/shiryo/woman-tsumitate-nisa.svg',
        woman_yukidama_korogasu_svg: '/assets/illusts/shiryo/woman-yukidama-korogasu.svg',
        woman_yukidama_tsukuru_svg: '/assets/illusts/shiryo/woman-yukidama-tsukuru.svg'
      },
      smartphone: {
        sp_fufu_kaisetsu_wc_svg: '/assets/illusts/smartphone/sp-fufu-kaisetsu-wc.svg',
        sp_man_sodan_svg: '/assets/illusts/smartphone/sp-man-sodan.svg',
        sp_woma_kaisetsu_wc_svg: '/assets/illusts/smartphone/sp-woma-kaisetsu-wc.svg',
        sp_woman_check_svg: '/assets/illusts/smartphone/sp-woman-check.svg',
        sp_woman_hikaku_svg: '/assets/illusts/smartphone/sp-woman-hikaku.svg',
        sp_woman_kaisetsu_mc_svg: '/assets/illusts/smartphone/sp-woman-kaisetsu-mc.svg',
        sp_woman_sodan_svg: '/assets/illusts/smartphone/sp-woman-sodan.svg',
        sp_woman_yoyaku_svg: '/assets/illusts/smartphone/sp-woman-yoyaku.svg'
      },
      user: {
        man_30_main_svg: '/assets/illusts/user/man-30-main.svg',
        man_30_sub_svg: '/assets/illusts/user/man-30-sub.svg',
        man_40_main_svg: '/assets/illusts/user/man-40-main.svg',
        man_50_main_svg: '/assets/illusts/user/man-50-main.svg',
        man_50_sub_svg: '/assets/illusts/user/man-50-sub.svg',
        man_60_main_svg: '/assets/illusts/user/man-60-main.svg',
        woman_30_main_svg: '/assets/illusts/user/woman-30-main.svg',
        woman_30_sub_svg: '/assets/illusts/user/woman-30-sub.svg',
        woman_40_main_svg: '/assets/illusts/user/woman-40-main.svg',
        woman_40_sub_svg: '/assets/illusts/user/woman-40-sub.svg',
        woman_50_main_svg: '/assets/illusts/user/woman-50-main.svg',
        woman_60_over_svg: '/assets/illusts/user/woman-60-over.svg'
      },
      wakaranai: {
        man_moyamoya_svg: '/assets/illusts/wakaranai/man-moyamoya.svg',
        man_komaru_svg: '/assets/illusts/wakaranai/man_komaru.svg',
        woman_kaigohiyou_futan_svg: '/assets/illusts/wakaranai/woman-kaigohiyou-futan.svg',
        woman_kanshin_svg: '/assets/illusts/wakaranai/woman-kanshin.svg',
        woman_kimerarenai_nisaideco_svg: '/assets/illusts/wakaranai/woman-kimerarenai-nisaideco.svg',
        woman_kimerarenai_svg: '/assets/illusts/wakaranai/woman-kimerarenai.svg',
        woman_komaru_svg: '/assets/illusts/wakaranai/woman-komaru.svg',
        woman_moyamoya_svg: '/assets/illusts/wakaranai/woman-moyamoya.svg',
        woman_setsuzei_ideco_svg: '/assets/illusts/wakaranai/woman-setsuzei-ideco.svg',
        woman_setsuzei_svg: '/assets/illusts/wakaranai/woman-setsuzei.svg',
        woman_shinchou_svg: '/assets/illusts/wakaranai/woman-shinchou.svg',
        woman_wakaranai_svg: '/assets/illusts/wakaranai/woman-wakaranai.svg',
        woman_zasetsu_svg: '/assets/illusts/wakaranai/woman-zasetsu.svg'
      },
      woman_concierge: {
        wc_ayamaru_svg: '/assets/illusts/woman-concierge/wc-ayamaru.svg',
        wc_basic_svg: '/assets/illusts/woman-concierge/wc-basic.svg',
        wc_chart_svg: '/assets/illusts/woman-concierge/wc-chart.svg',
        wc_hikaku_svg: '/assets/illusts/woman-concierge/wc-hikaku.svg',
        wc_instructor_svg: '/assets/illusts/woman-concierge/wc-instructor.svg',
        wc_kinshi_svg: '/assets/illusts/woman-concierge/wc-kinshi.svg',
        wc_ojigi_svg: '/assets/illusts/woman-concierge/wc-ojigi.svg',
        wc_ouen_svg: '/assets/illusts/woman-concierge/wc-ouen.svg',
        wc_sagasu_svg: '/assets/illusts/woman-concierge/wc-sagasu.svg',
        wc_smile_svg: '/assets/illusts/woman-concierge/wc-smile.svg',
        wc_sodan_svg: '/assets/illusts/woman-concierge/wc-sodan.svg',
        wc_star_svg: '/assets/illusts/woman-concierge/wc-star.svg',
        wc_sub_svg: '/assets/illusts/woman-concierge/wc-sub.svg',
        wc_unazuku_svg: '/assets/illusts/woman-concierge/wc-unazuku.svg'
      }
    },
    intention: {
      checkbox_svg: '/assets/intention/checkbox.svg',
      empty_checkbox_svg: '/assets/intention/empty-checkbox.svg',
      flow_svg: '/assets/intention/flow.svg',
      heart_check_svg: '/assets/intention/heart-check.svg',
      ideco_tsumitate_graphq_png: '/assets/intention/ideco-tsumitate-graphq.png',
      investment_trust_description_svg: '/assets/intention/investment-trust-description.svg',
      loss_taxable_graph_svg: '/assets/intention/loss-taxable-graph.svg',
      marunouchi_png: '/assets/intention/marunouchi.png',
      matrix_png: '/assets/intention/matrix.png',
      satisfaction_svg: '/assets/intention/satisfaction.svg',
      stable_graph_svg: '/assets/intention/stable-graph.svg',
      tax_graph_png: '/assets/intention/tax-graph.png',
      tsumitate_graph_svg: '/assets/intention/tsumitate-graph.svg',
      tsumitate_maintenance_graph_svg: '/assets/intention/tsumitate-maintenance-graph.svg',
      umeda_png: '/assets/intention/umeda.png',
      user_graph_svg: '/assets/intention/user-graph.svg',
      woman_low_risk_svg: '/assets/intention/woman-low-risk.svg'
    },
    invitations: {
      arrow_right_png: '/assets/invitations/arrow-right.png',
      hero_base_png: '/assets/invitations/hero-base.png',
      hero_md_png: '/assets/invitations/hero-md.png',
      mail_svg: '/assets/invitations/mail.svg'
    },
    layout: {
      header: {
        base: {
          banner_png: '/assets/layout/header/base/banner.png',
          consulting_icon_png: '/assets/layout/header/base/consulting-icon.png',
          instagram_icon_png: '/assets/layout/header/base/instagram-icon.png',
          line_icon_png: '/assets/layout/header/base/line-icon.png',
          mypage_icon_png: '/assets/layout/header/base/mypage-icon.png',
          seminar_icon_png: '/assets/layout/header/base/seminar-icon.png',
          simulation_icon_png: '/assets/layout/header/base/simulation-icon.png'
        },
        md: {
          $3_funds_seminar_png: '/assets/layout/header/md/3-funds-seminar.png',
          edu_consulting_png: '/assets/layout/header/md/edu-consulting.png',
          future_consulting_png: '/assets/layout/header/md/future-consulting.png',
          hand_with_sp_png: '/assets/layout/header/md/hand-with-sp.png',
          ideco_consulting_png: '/assets/layout/header/md/ideco-consulting.png',
          ideco_seminar_png: '/assets/layout/header/md/ideco-seminar.png',
          ideco2_seminar_png: '/assets/layout/header/md/ideco2-seminar.png',
          insurance_consulting_png: '/assets/layout/header/md/insurance-consulting.png',
          mortgage_seminar_png: '/assets/layout/header/md/mortgage-seminar.png',
          mutual_funds_consulting_png: '/assets/layout/header/md/mutual-funds-consulting.png',
          nisa_100_seminar_png: '/assets/layout/header/md/nisa-100-seminar.png',
          nisa_basic_seminar_png: '/assets/layout/header/md/nisa-basic-seminar.png',
          nisa_basic2_seminar_png: '/assets/layout/header/md/nisa-basic2-seminar.png',
          nisa_basic3_seminar_png: '/assets/layout/header/md/nisa-basic3-seminar.png',
          nisa_consulting_png: '/assets/layout/header/md/nisa-consulting.png',
          nisa_growth_seminar_png: '/assets/layout/header/md/nisa-growth-seminar.png',
          nisa_levelup_seminar_png: '/assets/layout/header/md/nisa-levelup-seminar.png',
          nisa_revise_seminar_png: '/assets/layout/header/md/nisa-revise-seminar.png',
          smart_seminar_png: '/assets/layout/header/md/smart-seminar.png',
          solution_50s_seminar_png: '/assets/layout/header/md/solution-50s-seminar.png'
        }
      }
    },
    lp: {
      $3_funds: {
        hero_base_png: '/assets/lp/3-funds/hero-base.png',
        hero_md_png: '/assets/lp/3-funds/hero-md.png',
        ogp_png: '/assets/lp/3-funds/ogp.png',
        recommend_base_png: '/assets/lp/3-funds/recommend-base.png',
        recommend_md_png: '/assets/lp/3-funds/recommend-md.png',
        slide01_01_png: '/assets/lp/3-funds/slide01_01.png',
        slide01_02_png: '/assets/lp/3-funds/slide01_02.png',
        slide01_03_png: '/assets/lp/3-funds/slide01_03.png',
        slide02_01_png: '/assets/lp/3-funds/slide02_01.png',
        slide02_02_png: '/assets/lp/3-funds/slide02_02.png',
        slide02_03_png: '/assets/lp/3-funds/slide02_03.png'
      },
      common: {
        applications_text_png: '/assets/lp/common/applications-text.png',
        checkbox_png: '/assets/lp/common/checkbox.png',
        consulting_participant_age_graph_png: '/assets/lp/common/consulting-participant-age-graph.png',
        graph_participants_age_png: '/assets/lp/common/graph-participants-age.png',
        graph_participants_financial_png: '/assets/lp/common/graph-participants-financial.png',
        manzokudo_badge_png: '/assets/lp/common/manzokudo-badge.png',
        merit_01_svg: '/assets/lp/common/merit-01.svg',
        merit_02_svg: '/assets/lp/common/merit-02.svg',
        number_of_visitors_badge_png: '/assets/lp/common/number-of-visitors_badge.png',
        preview_jpg: '/assets/lp/common/preview.jpg',
        star_png: '/assets/lp/common/star.png',
        taikensya_badge_png: '/assets/lp/common/taikensya-badge.png'
      },
      consulting: {
        edu: {
          hero_base_png: '/assets/lp/consulting/edu/hero-base.png',
          hero_md_png: '/assets/lp/consulting/edu/hero-md.png',
          ogp_jpg: '/assets/lp/consulting/edu/ogp.jpg'
        },
        future: {
          hero_base_png: '/assets/lp/consulting/future/hero-base.png',
          hero_md_png: '/assets/lp/consulting/future/hero-md.png',
          ogp_jpg: '/assets/lp/consulting/future/ogp.jpg'
        },
        ideco: {
          hero_base_png: '/assets/lp/consulting/ideco/hero-base.png',
          hero_md_png: '/assets/lp/consulting/ideco/hero-md.png',
          ogp_jpg: '/assets/lp/consulting/ideco/ogp.jpg'
        },
        insurance: {
          hero_base_png: '/assets/lp/consulting/insurance/hero-base.png',
          hero_md_png: '/assets/lp/consulting/insurance/hero-md.png',
          ogp_jpg: '/assets/lp/consulting/insurance/ogp.jpg',
          woman_kanshin_with_card_jpg: '/assets/lp/consulting/insurance/woman-kanshin-with-card.jpg'
        },
        mutual_funds: {
          hero_base_png: '/assets/lp/consulting/mutual-funds/hero-base.png',
          hero_md_png: '/assets/lp/consulting/mutual-funds/hero-md.png',
          ogp_jpg: '/assets/lp/consulting/mutual-funds/ogp.jpg'
        },
        nisa: {
          hero_base_png: '/assets/lp/consulting/nisa/hero-base.png',
          hero_md_png: '/assets/lp/consulting/nisa/hero-md.png',
          ogp_png: '/assets/lp/consulting/nisa/ogp.png'
        },
        sbi: {
          hero_base_png: '/assets/lp/consulting/sbi/hero-base.png',
          hero_md_png: '/assets/lp/consulting/sbi/hero-md.png'
        }
      },
      consulting_2: {
        hero_base_png: '/assets/lp/consulting-2/hero-base.png',
        hero_md_png: '/assets/lp/consulting-2/hero-md.png'
      },
      consulting_edu: {
        hero_base_jpg: '/assets/lp/consulting-edu/hero-base.jpg',
        hero_md_jpg: '/assets/lp/consulting-edu/hero-md.jpg',
        question_woman_png: '/assets/lp/consulting-edu/question_woman.png'
      },
      consulting_woman: {
        $9_9_38_img_9926fea5a7967daa27323fb4abc6cf9e592_png: '/assets/lp/consulting-woman/9_9_38_img_9926fea5a7967daa27323fb4abc6cf9e592.png',
        allow_png: '/assets/lp/consulting-woman/allow.png',
        checkbox_png: '/assets/lp/consulting-woman/checkbox.png',
        hero_base_jpg: '/assets/lp/consulting-woman/hero-base.jpg',
        hero_md_jpg: '/assets/lp/consulting-woman/hero-md.jpg',
        how_others_start_svg: '/assets/lp/consulting-woman/how-others-start.svg',
        prefer_female_disabled_png: '/assets/lp/consulting-woman/prefer-female-disabled.png',
        prefer_female_png: '/assets/lp/consulting-woman/prefer-female.png',
        prefer_male_disabled_png: '/assets/lp/consulting-woman/prefer-male-disabled.png',
        prefer_male_png: '/assets/lp/consulting-woman/prefer-male.png',
        prefer_none_disabled_png: '/assets/lp/consulting-woman/prefer-none-disabled.png',
        prefer_none_png: '/assets/lp/consulting-woman/prefer-none.png',
        prefer_female_disabled_pc_png: '/assets/lp/consulting-woman/prefer_female_disabled_pc.png',
        prefer_female_pc_png: '/assets/lp/consulting-woman/prefer_female_pc.png',
        prefer_male_disabled_pc_png: '/assets/lp/consulting-woman/prefer_male_disabled_pc.png',
        prefer_male_pc_png: '/assets/lp/consulting-woman/prefer_male_pc.png',
        prefer_none_disabled_pc_png: '/assets/lp/consulting-woman/prefer_none_disabled_pc.png',
        prefer_none_pc_png: '/assets/lp/consulting-woman/prefer_none_pc.png',
        speech_balloon_png: '/assets/lp/consulting-woman/speech-balloon.png',
        steps_content_pc_kojinsa_png: '/assets/lp/consulting-woman/steps-content-pc-kojinsa.png',
        steps_content_pc_moneiro_step_png: '/assets/lp/consulting-woman/steps-content-pc-moneiro-step.png',
        steps_content_pc_self_png: '/assets/lp/consulting-woman/steps-content-pc-self.png',
        steps_content_sp_moneiro_png: '/assets/lp/consulting-woman/steps-content-sp-moneiro.png',
        steps_content_sp_self_png: '/assets/lp/consulting-woman/steps-content-sp-self.png',
        steps_tab_bottom_arrow_svg: '/assets/lp/consulting-woman/steps-tab-bottom-arrow.svg',
        steps_tab_moneiro_color_svg: '/assets/lp/consulting-woman/steps-tab-moneiro-color.svg',
        steps_tab_moneiro_grayscale_svg: '/assets/lp/consulting-woman/steps-tab-moneiro-grayscale.svg',
        steps_tab_self_color_svg: '/assets/lp/consulting-woman/steps-tab-self-color.svg',
        steps_tab_self_grayscale_svg: '/assets/lp/consulting-woman/steps-tab-self-grayscale.svg'
      },
      ideco: {
        hero_base_png: '/assets/lp/ideco/hero-base.png',
        hero_md_png: '/assets/lp/ideco/hero-md.png',
        slide01_01_png: '/assets/lp/ideco/slide01_01.png',
        slide01_02_png: '/assets/lp/ideco/slide01_02.png',
        slide01_03_png: '/assets/lp/ideco/slide01_03.png',
        slide02_01_png: '/assets/lp/ideco/slide02_01.png',
        slide02_02_png: '/assets/lp/ideco/slide02_02.png',
        slide02_03_png: '/assets/lp/ideco/slide02_03.png'
      },
      ideco2: {
        hero_base_png: '/assets/lp/ideco2/hero-base.png',
        hero_md_png: '/assets/lp/ideco2/hero-md.png',
        ogp_png: '/assets/lp/ideco2/ogp.png',
        recommend_base_png: '/assets/lp/ideco2/recommend-base.png',
        recommend_md_png: '/assets/lp/ideco2/recommend-md.png',
        slide01_01_png: '/assets/lp/ideco2/slide01_01.png',
        slide01_02_png: '/assets/lp/ideco2/slide01_02.png',
        slide01_03_png: '/assets/lp/ideco2/slide01_03.png',
        slide02_01_png: '/assets/lp/ideco2/slide02_01.png',
        slide02_02_png: '/assets/lp/ideco2/slide02_02.png',
        slide02_03_png: '/assets/lp/ideco2/slide02_03.png'
      },
      illust: {
        about_nisaitems_svg: '/assets/lp/illust/about_nisaitems.svg',
        about_threefunds_svg: '/assets/lp/illust/about_threefunds.svg'
      },
      money_seminar: {
        academic_cap_svg: '/assets/lp/money-seminar/academic-cap.svg',
        age_png: '/assets/lp/money-seminar/age.png',
        beginner_ideco_nisa100_base_png: '/assets/lp/money-seminar/beginner-ideco-nisa100-base.png',
        beginner_ideco_nisa100_md_png: '/assets/lp/money-seminar/beginner-ideco-nisa100-md.png',
        beginner_ideco_nisa100_sq_png: '/assets/lp/money-seminar/beginner-ideco-nisa100-sq.png',
        beginner_mark_svg: '/assets/lp/money-seminar/beginner-mark.svg',
        family_base_png: '/assets/lp/money-seminar/family-base.png',
        family_md_png: '/assets/lp/money-seminar/family-md.png',
        family_pickup_png: '/assets/lp/money-seminar/family-pickup.png',
        family_sq_png: '/assets/lp/money-seminar/family-sq.png',
        general_30min_base_png: '/assets/lp/money-seminar/general-30min-base.png',
        general_30min_md_png: '/assets/lp/money-seminar/general-30min-md.png',
        general_30min_pickup_png: '/assets/lp/money-seminar/general-30min-pickup.png',
        general_30min_sq_png: '/assets/lp/money-seminar/general-30min-sq.png',
        graphs_svg: '/assets/lp/money-seminar/graphs.svg',
        hero_base_png: '/assets/lp/money-seminar/hero-base.png',
        hero_md_png: '/assets/lp/money-seminar/hero-md.png',
        house_png: '/assets/lp/money-seminar/house.png',
        ideco_base_png: '/assets/lp/money-seminar/ideco-base.png',
        ideco_md_png: '/assets/lp/money-seminar/ideco-md.png',
        ideco_sq_png: '/assets/lp/money-seminar/ideco-sq.png',
        ideco2_base_png: '/assets/lp/money-seminar/ideco2-base.png',
        ideco2_md_png: '/assets/lp/money-seminar/ideco2-md.png',
        mortgage_base_png: '/assets/lp/money-seminar/mortgage-base.png',
        mortgage_md_png: '/assets/lp/money-seminar/mortgage-md.png',
        nisa_basic_base_png: '/assets/lp/money-seminar/nisa-basic-base.png',
        nisa_basic_md_png: '/assets/lp/money-seminar/nisa-basic-md.png',
        nisa_basic_pickup_png: '/assets/lp/money-seminar/nisa-basic-pickup.png',
        nisa_basic2_base_png: '/assets/lp/money-seminar/nisa-basic2-base.png',
        nisa_basic2_md_png: '/assets/lp/money-seminar/nisa-basic2-md.png',
        nisa_basic3_base_png: '/assets/lp/money-seminar/nisa-basic3-base.png',
        nisa_basic3_md_png: '/assets/lp/money-seminar/nisa-basic3-md.png',
        nisa_basic3_pickup_png: '/assets/lp/money-seminar/nisa-basic3-pickup.png',
        nisa_growth_hero_base_png: '/assets/lp/money-seminar/nisa-growth-hero-base.png',
        nisa_growth_hero_md_png: '/assets/lp/money-seminar/nisa-growth-hero-md.png',
        nisa_levelup_base_png: '/assets/lp/money-seminar/nisa-levelup-base.png',
        nisa_levelup_md_png: '/assets/lp/money-seminar/nisa-levelup-md.png',
        nisa_revise_base_png: '/assets/lp/money-seminar/nisa-revise-base.png',
        nisa_revise_md_png: '/assets/lp/money-seminar/nisa-revise-md.png',
        pig_chokinbako_png: '/assets/lp/money-seminar/pig-chokinbako.png',
        seeding_svg: '/assets/lp/money-seminar/seeding.svg',
        solution_50s_base_png: '/assets/lp/money-seminar/solution-50s-base.png',
        solution_50s_md_png: '/assets/lp/money-seminar/solution-50s-md.png'
      },
      mortgage: {
        hero_base_png: '/assets/lp/mortgage/hero-base.png',
        hero_md_png: '/assets/lp/mortgage/hero-md.png',
        mogecheck_png: '/assets/lp/mortgage/mogecheck.png',
        ogp_png: '/assets/lp/mortgage/ogp.png',
        recommend_base_png: '/assets/lp/mortgage/recommend-base.png',
        recommend_md_png: '/assets/lp/mortgage/recommend-md.png',
        slide01_01_png: '/assets/lp/mortgage/slide01_01.png',
        slide01_02_png: '/assets/lp/mortgage/slide01_02.png',
        slide01_03_png: '/assets/lp/mortgage/slide01_03.png',
        slide02_01_png: '/assets/lp/mortgage/slide02_01.png',
        slide02_02_png: '/assets/lp/mortgage/slide02_02.png',
        slide02_03_png: '/assets/lp/mortgage/slide02_03.png',
        slide03_01_png: '/assets/lp/mortgage/slide03_01.png',
        slide03_02_png: '/assets/lp/mortgage/slide03_02.png',
        slide03_03_png: '/assets/lp/mortgage/slide03_03.png'
      },
      nisa_basic: {
        hero_base_png: '/assets/lp/nisa-basic/hero-base.png',
        hero_md_png: '/assets/lp/nisa-basic/hero-md.png',
        ogp_png: '/assets/lp/nisa-basic/ogp.png',
        slide01_01_png: '/assets/lp/nisa-basic/slide01_01.png',
        slide01_02_png: '/assets/lp/nisa-basic/slide01_02.png',
        slide01_03_png: '/assets/lp/nisa-basic/slide01_03.png',
        slide02_01_png: '/assets/lp/nisa-basic/slide02_01.png',
        slide02_02_png: '/assets/lp/nisa-basic/slide02_02.png',
        slide02_03_png: '/assets/lp/nisa-basic/slide02_03.png'
      },
      nisa_basic2: {
        hero_base_png: '/assets/lp/nisa-basic2/hero-base.png',
        hero_md_png: '/assets/lp/nisa-basic2/hero-md.png',
        ogp_png: '/assets/lp/nisa-basic2/ogp.png',
        slide01_01_png: '/assets/lp/nisa-basic2/slide01_01.png',
        slide01_02_png: '/assets/lp/nisa-basic2/slide01_02.png',
        slide01_03_png: '/assets/lp/nisa-basic2/slide01_03.png',
        slide02_01_png: '/assets/lp/nisa-basic2/slide02_01.png',
        slide02_02_png: '/assets/lp/nisa-basic2/slide02_02.png',
        slide02_03_png: '/assets/lp/nisa-basic2/slide02_03.png'
      },
      nisa_basic3: {
        hero_base_png: '/assets/lp/nisa-basic3/hero-base.png',
        hero_md_png: '/assets/lp/nisa-basic3/hero-md.png',
        ogp_png: '/assets/lp/nisa-basic3/ogp.png',
        recommend_base_png: '/assets/lp/nisa-basic3/recommend-base.png',
        recommend_md_png: '/assets/lp/nisa-basic3/recommend-md.png',
        slide01_01_png: '/assets/lp/nisa-basic3/slide01_01.png',
        slide01_02_png: '/assets/lp/nisa-basic3/slide01_02.png',
        slide01_03_png: '/assets/lp/nisa-basic3/slide01_03.png',
        slide02_01_png: '/assets/lp/nisa-basic3/slide02_01.png',
        slide02_02_png: '/assets/lp/nisa-basic3/slide02_02.png',
        slide02_03_png: '/assets/lp/nisa-basic3/slide02_03.png'
      },
      nisa_growth: {
        hero_base_png: '/assets/lp/nisa-growth/hero-base.png',
        hero_md_png: '/assets/lp/nisa-growth/hero-md.png',
        ogp_png: '/assets/lp/nisa-growth/ogp.png',
        recommend_base_png: '/assets/lp/nisa-growth/recommend-base.png',
        recommend_md_png: '/assets/lp/nisa-growth/recommend-md.png',
        slide01_01_png: '/assets/lp/nisa-growth/slide01_01.png',
        slide01_02_png: '/assets/lp/nisa-growth/slide01_02.png',
        slide01_03_png: '/assets/lp/nisa-growth/slide01_03.png',
        slide02_01_png: '/assets/lp/nisa-growth/slide02_01.png',
        slide02_02_png: '/assets/lp/nisa-growth/slide02_02.png',
        slide02_03_png: '/assets/lp/nisa-growth/slide02_03.png'
      },
      nisa_levelup: {
        hero_base_png: '/assets/lp/nisa-levelup/hero-base.png',
        hero_md_png: '/assets/lp/nisa-levelup/hero-md.png',
        ogp_png: '/assets/lp/nisa-levelup/ogp.png',
        slide01_01_png: '/assets/lp/nisa-levelup/slide01_01.png',
        slide01_02_png: '/assets/lp/nisa-levelup/slide01_02.png',
        slide01_03_png: '/assets/lp/nisa-levelup/slide01_03.png',
        slide02_01_png: '/assets/lp/nisa-levelup/slide02_01.png',
        slide02_02_png: '/assets/lp/nisa-levelup/slide02_02.png',
        slide02_03_png: '/assets/lp/nisa-levelup/slide02_03.png'
      },
      nisa_revise: {
        hero_base_png: '/assets/lp/nisa-revise/hero-base.png',
        hero_md_png: '/assets/lp/nisa-revise/hero-md.png',
        ogp_png: '/assets/lp/nisa-revise/ogp.png',
        slide01_01_png: '/assets/lp/nisa-revise/slide01_01.png',
        slide01_02_png: '/assets/lp/nisa-revise/slide01_02.png',
        slide01_03_png: '/assets/lp/nisa-revise/slide01_03.png',
        slide02_01_png: '/assets/lp/nisa-revise/slide02_01.png',
        slide02_02_png: '/assets/lp/nisa-revise/slide02_02.png',
        slide02_03_png: '/assets/lp/nisa-revise/slide02_03.png',
        slide03_01_png: '/assets/lp/nisa-revise/slide03_01.png',
        slide03_02_png: '/assets/lp/nisa-revise/slide03_02.png',
        slide03_03_png: '/assets/lp/nisa-revise/slide03_03.png'
      },
      shindan_start: {
        financial_products_png: '/assets/lp/shindan-start/financial-products.png',
        moneiro_choice_png: '/assets/lp/shindan-start/moneiro-choice.png'
      },
      smart: {
        hero_base_png: '/assets/lp/smart/hero-base.png',
        hero_md_png: '/assets/lp/smart/hero-md.png',
        ogp_png: '/assets/lp/smart/ogp.png',
        recommend_base_png: '/assets/lp/smart/recommend-base.png',
        recommend_md_png: '/assets/lp/smart/recommend-md.png',
        slide01_01_png: '/assets/lp/smart/slide01_01.png',
        slide01_02_png: '/assets/lp/smart/slide01_02.png',
        slide01_03_png: '/assets/lp/smart/slide01_03.png',
        slide02_01_png: '/assets/lp/smart/slide02_01.png',
        slide02_02_png: '/assets/lp/smart/slide02_02.png',
        slide02_03_png: '/assets/lp/smart/slide02_03.png',
        slide02_04_png: '/assets/lp/smart/slide02_04.png'
      },
      solution_50s: {
        hero_base_png: '/assets/lp/solution-50s/hero-base.png',
        hero_md_png: '/assets/lp/solution-50s/hero-md.png',
        ogp_solution_50s_png: '/assets/lp/solution-50s/ogp_solution-50s.png',
        rcommend_base_png: '/assets/lp/solution-50s/rcommend_base.png',
        rcommend_md_png: '/assets/lp/solution-50s/rcommend_md.png',
        slide01_01_png: '/assets/lp/solution-50s/slide01_01.png',
        slide01_02_png: '/assets/lp/solution-50s/slide01_02.png',
        slide01_03_png: '/assets/lp/solution-50s/slide01_03.png',
        slide02_01_png: '/assets/lp/solution-50s/slide02_01.png',
        slide02_02_png: '/assets/lp/solution-50s/slide02_02.png',
        slide02_03_png: '/assets/lp/solution-50s/slide02_03.png'
      },
      top: {
        a_svg: '/assets/lp/top/a.svg',
        after_plan_graph_1_png: '/assets/lp/top/after-plan-graph-1.png',
        after_plan_graph_2_png: '/assets/lp/top/after-plan-graph-2.png',
        after_plan_graph_3_png: '/assets/lp/top/after-plan-graph-3.png',
        arrow_down_png: '/assets/lp/top/arrow-down.png',
        arrow_right_png: '/assets/lp/top/arrow-right.png',
        before_plan_graph_1_png: '/assets/lp/top/before-plan-graph-1.png',
        before_plan_graph_2_png: '/assets/lp/top/before-plan-graph-2.png',
        before_plan_graph_3_png: '/assets/lp/top/before-plan-graph-3.png',
        logo_amexpo_png: '/assets/lp/top/logo-amexpo.png',
        logo_forbes_png: '/assets/lp/top/logo-forbes.png',
        logo_hiruobi_png: '/assets/lp/top/logo-hiruobi.png',
        logo_jwave_png: '/assets/lp/top/logo-jwave.png',
        logo_news_dig_png: '/assets/lp/top/logo-news-dig.png',
        logo_newspicks_png: '/assets/lp/top/logo-newspicks.png',
        logo_tc_png: '/assets/lp/top/logo-tc.png',
        logo_thebridge_png: '/assets/lp/top/logo-thebridge.png',
        q_svg: '/assets/lp/top/q.svg',
        simulation_3min_svg: '/assets/lp/top/simulation-3min.svg',
        star_svg: '/assets/lp/top/star.svg',
        steps_content_pc_self_png: '/assets/lp/top/steps-content-pc-self.png',
        steps_content_sp_moneiro_png: '/assets/lp/top/steps-content-sp-moneiro.png',
        steps_content_sp_self_png: '/assets/lp/top/steps-content-sp-self.png',
        steps_tab_bottom_arrow_svg: '/assets/lp/top/steps-tab-bottom-arrow.svg',
        steps_tab_moneiro_color_svg: '/assets/lp/top/steps-tab-moneiro-color.svg',
        steps_tab_moneiro_grayscale_svg: '/assets/lp/top/steps-tab-moneiro-grayscale.svg',
        steps_tab_self_color_svg: '/assets/lp/top/steps-tab-self-color.svg',
        steps_tab_self_grayscale_svg: '/assets/lp/top/steps-tab-self-grayscale.svg',
        steps_content_pc_moneiro_png: '/assets/lp/top/steps_content_pc_moneiro.png',
        storm_png: '/assets/lp/top/storm.png'
      },
      tsumitate_nisa_100: {
        hero_base_png: '/assets/lp/tsumitate-nisa-100/hero-base.png',
        hero_md_png: '/assets/lp/tsumitate-nisa-100/hero-md.png',
        ogp_png: '/assets/lp/tsumitate-nisa-100/ogp.png',
        slide01_01_png: '/assets/lp/tsumitate-nisa-100/slide01_01.png',
        slide01_02_png: '/assets/lp/tsumitate-nisa-100/slide01_02.png',
        slide02_01_png: '/assets/lp/tsumitate-nisa-100/slide02_01.png',
        slide02_02_png: '/assets/lp/tsumitate-nisa-100/slide02_02.png',
        slide02_03_png: '/assets/lp/tsumitate-nisa-100/slide02_03.png',
        slide02_04_png: '/assets/lp/tsumitate-nisa-100/slide02_04.png'
      },
      woman_common: {
        available_online_png: '/assets/lp/woman-common/available-online.png',
        available_online2_png: '/assets/lp/woman-common/available-online2.png',
        banner_jpg: '/assets/lp/woman-common/banner.jpg',
        chapter_1_png: '/assets/lp/woman-common/chapter-1.png',
        chapter_2_png: '/assets/lp/woman-common/chapter-2.png',
        chishiki0_png: '/assets/lp/woman-common/chishiki0.png',
        edu_simulation_png: '/assets/lp/woman-common/edu_simulation.png',
        flow_title_step_01_svg: '/assets/lp/woman-common/flow_title_step_01.svg',
        flow_title_step_02_svg: '/assets/lp/woman-common/flow_title_step_02.svg',
        flow_title_step_03_svg: '/assets/lp/woman-common/flow_title_step_03.svg',
        fund_png: '/assets/lp/woman-common/fund.png',
        good_point_title_01_svg: '/assets/lp/woman-common/good_point_title_01.svg',
        good_point_title_02_svg: '/assets/lp/woman-common/good_point_title_02.svg',
        good_point_title_03_svg: '/assets/lp/woman-common/good_point_title_03.svg',
        good_woman_png: '/assets/lp/woman-common/good_woman.png',
        graph_woman_png: '/assets/lp/woman-common/graph_woman.png',
        ok_woman_png: '/assets/lp/woman-common/ok_woman.png',
        q1_png: '/assets/lp/woman-common/q1.png',
        q2_png: '/assets/lp/woman-common/q2.png',
        q3_png: '/assets/lp/woman-common/q3.png',
        q4_png: '/assets/lp/woman-common/q4.png',
        question_woman_png: '/assets/lp/woman-common/question-woman.png',
        sandglass_woman_png: '/assets/lp/woman-common/sandglass_woman.png',
        select_woman_png: '/assets/lp/woman-common/select_woman.png',
        simulation_result_png: '/assets/lp/woman-common/simulation_result.png',
        three_women_png: '/assets/lp/woman-common/three_women.png',
        woman_consulting_participant_age_graph_png: '/assets/lp/woman-common/woman-consulting-participant-age-graph.png',
        woman_money_png: '/assets/lp/woman-common/woman_money.png',
        writing_woman_png: '/assets/lp/woman-common/writing_woman.png'
      }
    },
    media: {
      ad: {
        banner: {
          $3funds_f_png: '/assets/media/ad/banner/3funds-f.png',
          family_f_png: '/assets/media/ad/banner/family-f.png',
          family_jpg: '/assets/media/ad/banner/family.jpg',
          smart_f_png: '/assets/media/ad/banner/smart-f.png',
          smart_png: '/assets/media/ad/banner/smart.png',
          tn100_f_png: '/assets/media/ad/banner/tn100-f.png',
          tn100_png: '/assets/media/ad/banner/tn100.png'
        },
        bg_moneiro_png: '/assets/media/ad/bg-moneiro.png',
        speaker_png: '/assets/media/ad/speaker.png',
        woman_png: '/assets/media/ad/woman.png'
      },
      common: {
        hand_with_smartphone_png: '/assets/media/common/hand-with-smartphone.png',
        ogp_png: '/assets/media/common/ogp.png'
      },
      creditcard_svg: '/assets/media/creditcard.svg',
      default: {
        header_icon_png: '/assets/media/default/header-icon.png'
      },
      entry: {
        book_icon_svg: '/assets/media/entry/book-icon.svg',
        caution_icon_svg: '/assets/media/entry/caution-icon.svg',
        female_concierge_svg: '/assets/media/entry/female-concierge.svg',
        female_user_svg: '/assets/media/entry/female-user.svg',
        male_concierge_svg: '/assets/media/entry/male-concierge.svg',
        pen_icon_svg: '/assets/media/entry/pen-icon.svg'
      },
      feature_entry: {
        arrow_down_svg: '/assets/media/feature-entry/arrow-down.svg',
        kv: {
          bg_book_md_png: '/assets/media/feature-entry/kv/bg-book-md.png',
          men_png: '/assets/media/feature-entry/kv/men.png',
          women_png: '/assets/media/feature-entry/kv/women.png'
        }
      },
      snf_logo_dark_png: '/assets/media/snf-logo-dark.png',
      snf_logo_png: '/assets/media/snf-logo.png',
      social: {
        facebook_svg: '/assets/media/social/facebook.svg',
        hatebu_svg: '/assets/media/social/hatebu.svg',
        hatena_svg: '/assets/media/social/hatena.svg',
        instagram_svg: '/assets/media/social/instagram.svg',
        line_svg: '/assets/media/social/line.svg',
        linkedin_svg: '/assets/media/social/linkedin.svg',
        note_svg: '/assets/media/social/note.svg',
        twitter_svg: '/assets/media/social/twitter.svg'
      },
      woman_ideco_setsuzei_png: '/assets/media/woman-ideco-setsuzei.png'
    },
    omp_members: {
      harada_png: '/assets/omp-members/harada.png',
      hujino_jpg: '/assets/omp-members/hujino.jpg',
      kamura_profile_jpg: '/assets/omp-members/kamura-profile.jpg',
      kamura_png: '/assets/omp-members/kamura.png',
      miyazawa_png: '/assets/omp-members/miyazawa.png',
      nishimori_jpg: '/assets/omp-members/nishimori.jpg',
      oono_png: '/assets/omp-members/oono.png',
      sato_jpg: '/assets/omp-members/sato.jpg',
      shiozawa_jpg: '/assets/omp-members/shiozawa.jpg',
      takahashi_jpg: '/assets/omp-members/takahashi.jpg',
      takahashi_profile_jpg: '/assets/omp-members/takahashi_profile.jpg',
      ytanaka_png: '/assets/omp-members/ytanaka.png'
    },
    package_json: '/assets/package.json',
    seminar_speakers: {
      $1_jpg: '/assets/seminar-speakers/1.jpg',
      $5_jpg: '/assets/seminar-speakers/5.jpg',
      $7_jpg: '/assets/seminar-speakers/7.jpg'
    },
    seminar_videos: {
      bgImage_jpg: '/assets/seminar-videos/bgImage.jpg',
      seminar_speaker_png: '/assets/seminar-videos/seminar-speaker.png',
      seminar_speaker2x_png: '/assets/seminar-videos/seminar-speaker2x.png'
    },
    seminars: {
      crown_png: '/assets/seminars/crown.png',
      widgetHeader_png: '/assets/seminars/widgetHeader.png'
    },
    shindan: {
      ogp: {
        ogp_diy_gambler_man_jpg: '/assets/shindan/ogp/ogp-diy_gambler_man.jpg',
        ogp_diy_gambler_woman_jpg: '/assets/shindan/ogp/ogp-diy_gambler_woman.jpg',
        ogp_diy_steady_man_jpg: '/assets/shindan/ogp/ogp-diy_steady_man.jpg',
        ogp_diy_steady_woman_jpg: '/assets/shindan/ogp/ogp-diy_steady_woman.jpg',
        ogp_gambler_man_jpg: '/assets/shindan/ogp/ogp-gambler_man.jpg',
        ogp_gambler_woman_jpg: '/assets/shindan/ogp/ogp-gambler_woman.jpg',
        ogp_steady_man_jpg: '/assets/shindan/ogp/ogp-steady_man.jpg',
        ogp_steady_woman_jpg: '/assets/shindan/ogp/ogp-steady_woman.jpg'
      }
    },
    simulation: {
      $3min: {
        illust: {
          gg_check_o_png: '/assets/simulation/3min/illust/gg_check-o.png',
          simulation_3min_actual_machine_image_png: '/assets/simulation/3min/illust/simulation-3min-actual-machine-image.png'
        },
        lp: {
          about_moneiro_concier_heading_png: '/assets/simulation/3min/lp/about-moneiro-concier-heading.png',
          hero_base_png: '/assets/simulation/3min/lp/hero-base.png',
          hero_md_png: '/assets/simulation/3min/lp/hero-md.png',
          nisa_base_png: '/assets/simulation/3min/lp/nisa-base.png',
          nisa_md_png: '/assets/simulation/3min/lp/nisa-md.png',
          ogp_png: '/assets/simulation/3min/lp/ogp.png'
        },
        message: {
          step1_svg: '/assets/simulation/3min/message/step1.svg',
          step2_svg: '/assets/simulation/3min/message/step2.svg',
          step3_svg: '/assets/simulation/3min/message/step3.svg',
          step4_svg: '/assets/simulation/3min/message/step4.svg',
          step5_svg: '/assets/simulation/3min/message/step5.svg'
        },
        result: {
          arrow_down_blue_png: '/assets/simulation/3min/result/arrow-down-blue.png',
          arrow_right_blue_png: '/assets/simulation/3min/result/arrow-right-blue.png',
          piggy_bank_svg: '/assets/simulation/3min/result/piggy-bank.svg',
          tsumitate_block_svg: '/assets/simulation/3min/result/tsumitate-block.svg'
        },
        start: {
          tutorial_modal: {
            step_1_png: '/assets/simulation/3min/start/tutorial-modal/step-1.png',
            step_2_png: '/assets/simulation/3min/start/tutorial-modal/step-2.png'
          }
        }
      },
      nisa: {
        commentary: {
          operation_image_graph_png: '/assets/simulation/nisa/commentary/operation-image-graph.png',
          sp500_crash_png: '/assets/simulation/nisa/commentary/sp500-crash.png'
        },
        lp: {
          hero_base_png: '/assets/simulation/nisa/lp/hero-base.png',
          hero_md_png: '/assets/simulation/nisa/lp/hero-md.png',
          simulation_result_png: '/assets/simulation/nisa/lp/simulation-result.png',
          woman_orange_circle_png: '/assets/simulation/nisa/lp/woman-orange-circle.png'
        },
        main_visual: {
          concier: {
            glitter_png: '/assets/simulation/nisa/main-visual/concier/glitter.png',
            smile_png: '/assets/simulation/nisa/main-visual/concier/smile.png',
            sweat_png: '/assets/simulation/nisa/main-visual/concier/sweat.png'
          },
          graph: {
            education_fund_preparation_1_png: '/assets/simulation/nisa/main-visual/graph/education-fund-preparation-1.png',
            education_fund_preparation_2_png: '/assets/simulation/nisa/main-visual/graph/education-fund-preparation-2.png',
            future_fund_preparation_1_png: '/assets/simulation/nisa/main-visual/graph/future-fund-preparation-1.png',
            future_fund_preparation_2_png: '/assets/simulation/nisa/main-visual/graph/future-fund-preparation-2.png',
            future_fund_preparation_3_png: '/assets/simulation/nisa/main-visual/graph/future-fund-preparation-3.png',
            future_fund_preparation_4_png: '/assets/simulation/nisa/main-visual/graph/future-fund-preparation-4.png',
            future_fund_preparation_5_png: '/assets/simulation/nisa/main-visual/graph/future-fund-preparation-5.png',
            no_1_png: '/assets/simulation/nisa/main-visual/graph/no-1.png',
            no_2_png: '/assets/simulation/nisa/main-visual/graph/no-2.png',
            no_3_png: '/assets/simulation/nisa/main-visual/graph/no-3.png',
            pocket_money_earning_1_png: '/assets/simulation/nisa/main-visual/graph/pocket-money-earning-1.png',
            pocket_money_earning_2_png: '/assets/simulation/nisa/main-visual/graph/pocket-money-earning-2.png',
            pocket_money_earning_3_png: '/assets/simulation/nisa/main-visual/graph/pocket-money-earning-3.png'
          }
        },
        next_action: {
          sbi: {
            key_visual_png: '/assets/simulation/nisa/next-action/sbi/key-visual.png'
          },
          seminar_edu: {
            key_visual_png: '/assets/simulation/nisa/next-action/seminar-edu/key-visual.png'
          },
          simulation_3min: {
            mock_png: '/assets/simulation/nisa/next-action/simulation-3min/mock.png',
            point_png: '/assets/simulation/nisa/next-action/simulation-3min/point.png'
          }
        },
        ogp_png: '/assets/simulation/nisa/ogp.png'
      }
    },
    sns: {
      line: {
        add_friend_png: '/assets/sns/line/add-friend.png',
        icon_png: '/assets/sns/line/icon.png'
      }
    },
    top: {
      movie_thumbnail_png: '/assets/top/movie-thumbnail.png',
      mv_1_png: '/assets/top/mv-1.png',
      mv_2_png: '/assets/top/mv-2.png',
      mv_3_png: '/assets/top/mv-3.png',
      mv_4_png: '/assets/top/mv-4.png'
    }
  },
  corp_og_jpg: '/corp-og.jpg',
  favicon_ico: '/favicon.ico',
  favicon_svg: '/favicon.svg',
  logo_svg: '/logo.svg',
  nortonsw_7d17b800_6523_0_html: '/nortonsw_7d17b800-6523-0.html',
  og_png: '/og.png',
  opensearch_xml: '/opensearch.xml',
  robots_txt: '/robots.txt'
} as const;

export type StaticPath = typeof staticPath;
